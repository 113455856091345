/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
import { Button, Card, Col, List, Row, Typography, message } from "antd";
import { CgInfinity } from "react-icons/cg";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { baseUrl, getProfile } from "../services/services.profile";
import { current } from "@reduxjs/toolkit";

var plans = [
  {
    title: "COMPLIANCE_5",
    price: "FREE",
    priceId: "COMPLIANCE_5",
    list: [
      { title: "Inspection Reports", icon: "5" },
      { title: "Transaction Reports", icon: "Included" },
      { title: "CDD Reports", icon: "Unlimited" },
      {
        title: "Ongoing Monitoring",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
      // {
      //   title: "Branded Onboarding Link",
      //   icon: <RiCloseFill size={22} color={"red"} />,
      // },
      // { title: "Premium API", icon: <RiCheckFill size={22} color={"green"} /> },
    ],
    text: "iPass Pro is pleased to offer starting art market participants free access to the AML tools and resources needed to grow with integrity and confidence while keeping costs under control. Conditions apply.",
  },
  {
    title: "COMPLIANCE_25",
    price: "£ 295",
    priceId: "COMPLIANCE_25",
    list: [
      { title: "Inspection Reports", icon: "25" },
      { title: "Transaction Reports", icon: "Included" },
      { title: "CDD Reports", icon: "Unlimited" },
      {
        title: "Ongoing Monitoring",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
      {
        title: "Branded Onboarding Link",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
      { title: "Premium API", icon: <RiCloseFill size={22} color={"red"} /> },
       
    ],
  },
  {
    title: "COMPLIANCE_50",
    price: "£ 550",
    priceId: "COMPLIANCE_50",
    list: [
       { title: "Inspection Reports", icon: "50" },
      { title: "Transaction Reports", icon: "Included" },
      { title: "CDD Reports", icon: "Unlimited" },
      {
        title: "Ongoing Monitoring",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
      {
        title: "Branded Onboarding Link",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
       { title: "Premium API", icon: <RiCloseFill size={22} color={"red"} /> },
    ],
  },
  {
    title: "COMPLIANCE_100",
    price: "£ 995",
    priceId: "COMPLIANCE_100",
    list: [
      { title: "Inspection Reports", icon: "100" },
      { title: "Transaction Reports", icon: "Included" },
      { title: "CDD Reports", icon: "Unlimited" },
      {
        title: "Ongoing Monitoring",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
      {
        title: "Branded Onboarding Link",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
      { title: "Premium API", icon: <RiCloseFill size={22} color={"red"} /> },
    ],
  },
  {
    title: "COMPLIANCE_250",
    price: "£ 2,375",
    priceId: "COMPLIANCE_250",
    list: [
      { title: "Inspection Reports", icon: "250" },
      { title: "Transaction Reports", icon: "Included" },
      { title: "CDD Reports", icon: "Unlimited" },
      {
        title: "Ongoing Monitoring",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
      {
        title: "Branded Onboarding Link",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
      { title: "Premium API", icon: <RiCheckFill size={22} color={"green"} /> },
    ],
  },
  {
    title: "COMPLIANCE_500",
    price: "£ 4,500",
    priceId: "COMPLIANCE_500",
    list: [
       { title: "Inspection Reports", icon: "500" },
      { title: "Transaction Reports", icon: "Included" },
      { title: "CDD Reports", icon: "Unlimited" },
      {
        title: "Ongoing Monitoring",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
      {
        title: "Branded Onboarding Link",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
      { title: "Premium API", icon: <RiCheckFill size={22} color={"green"} /> },
    ],
  },
  {
    title: "COMPLIANCE_750",
    price: "£ 6,375",
    priceId: "COMPLIANCE_750",
    list: [
     { title: "Inspection Reports", icon: "750" },
      { title: "Transaction Reports", icon: "Included" },
      { title: "CDD Reports", icon: "Unlimited" },
      {
        title: "Ongoing Monitoring",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
      {
        title: "Branded Onboarding Link",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
      { title: "Premium API", icon: <RiCheckFill size={22} color={"green"} /> },
    ],
  },
  {
    title: "COMPLIANCE_1000",
    price: "£ 7,950",
    priceId: "COMPLIANCE_1000",
    list: [
      { title: "Inspection Reports", icon: "1000" },
      { title: "Transaction Reports", icon: "Included" },
      { title: "CDD Reports", icon: "Unlimited" },
      {
        title: "Ongoing Monitoring",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
      {
        title: "Branded Onboarding Link",
        icon: <RiCheckFill size={22} color={"green"} />,
      },
      { title: "Premium API", icon: <RiCheckFill size={22} color={"green"} /> },
    ],
  },
];

function Pricing() {
  const [config, setConfig] = useState({});
  const [alreadyselectprice, Setalreadyselectprice] = useState(null);
  // const [selectprice, Setselectprice] = useState();
  const navigate = useNavigate();
  //getconfig
  useEffect(() => {
    const getConfig = async () => {
      const config = await axios.get(baseUrl + "/payment/getconfig");
      setConfig(config.data);
    };
    getConfig();
  }, []);

  const handleSelect = async (priceId) => {
    let obj = plans.find((o) => o.priceId === priceId);
    let oldprice = "";
    let currentres = {};

    getProfile()
      .then(async (res) => {
        if(res.membership) {
          console.log(res.membership.name.replace(" ", "_").toUpperCase(), "res");
          currentres = res;
          // setTimeout(()=>{
          //  await setCurrentInfo(res.sla_sign?.sub_info?.priceId)
          // console.log(currentinfo,"INFO")
          plans.forEach((x) => {
            const memberShipPrice = res.membership.name
              .replace(" ", "_")
              .toUpperCase();
            if (x.priceId === memberShipPrice) {
              Setalreadyselectprice(x.price);
              oldprice = x.price;
            }
          });
          // },2000)
        }
      })
      .then(async (re) => {
        // if(Number(oldprice.replace(/[^0-9\.-]+/g,"")) ==Number(obj.price.replace(/[^0-9\.-]+/g,"")) || priceId =='COMPLIANCE_5'){
        // if (
        //   Number(oldprice.replace(/[^0-9\.-]+/g, "")) >
        //     Number(obj.price.replace(/[^0-9\.-]+/g, "")) &&
        //   currentres.subscription
        // )
        if (
          Number(oldprice.replace(/[^0-9\.-]+/g, "")) ==
          Number(obj.price.replace(/[^0-9\.-]+/g, ""))
        ) {
          // localStorage.setItem("mem_page", "details");
          // navigate("/user/membership");
          message.error("Please select upgrade subscription plan");
        } else {
          await axios
            .post(baseUrl + "/api/sub/sla", {
              priceId: priceId,
              customerId: config.userInfo?.customerId,
              company: config.userInfo?.company,
            })
            .then((res) => {
              if (currentres.subscription) {
                // if(Number(oldprice.replace(/[^0-9\.-]+/g,"")) >Number(obj.price.replace(/[^0-9\.-]+/g,"")) || priceId =='COMPLIANCE_5'){
                //   // alert("no Downgrade")
                //   message.error("Downgrade is not possible");

                // }else
                if (
                  Number(oldprice.replace(/[^0-9\.-]+/g, "")) ==
                  Number(obj.price.replace(/[^0-9\.-]+/g, ""))
                ) {
                  localStorage.setItem("mem_page", "details");
                  navigate("/user/membership");
                } else if (
                  Number(oldprice.replace(/[^0-9\.-]+/g, "")) <
                  Number(obj.price.replace(/[^0-9\.-]+/g, ""))
                ) {
                  localStorage.setItem("mem_page", "ela-sign");
                  navigate("/user/membership");
                }
              } else {
                localStorage.setItem("mem_page", "ela-sign");
                navigate("/user/membership");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfile()
      .then(async (res) => {
        console.log(res, "res", res.utype);
        if (res.utype == "PATRON") {
          message.error("Cannot access an area reserved for iPass Pro members.");
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
        <>
          <Row gutter={[24, 48]}>
            {plans.map((plan, index) => (
              <Col key={index} span={6}>
                <Card
                  className="bg-light"
                  bodyStyle={{ padding: "0px" }}
                  title={plan.title}
                  key={index}
                  actions={[
                    <Button type="text">{plan.price}</Button>,
                    <Button
                      onClick={() => {
                        handleSelect(plan.priceId);
                      }}
                      type="primary"
                    >
                      SELECT
                    </Button>,
                  ]}
                >
                  <List
                    key={index}
                    size="small"
                    itemLayout="horizontal"
                    dataSource={plan.list}
                    renderItem={(item) => (
                      <List.Item actions={[item.icon]}>
                        <List.Item.Meta title={item.title} />
                      </List.Item>
                    )}
                  />
                  {plan.text && (
                    <Typography.Paragraph
                      style={{
                        padding: "5px",
                        fontSize: "14px",
                        textAlign: "center",
                        minHeight: "100px",
                      }}
                    >
                      {plan.text}
                    </Typography.Paragraph>
                  )}
                </Card>
              </Col>
            ))}
          </Row>
        </>
   
  );
}

export default Pricing;
