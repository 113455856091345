import { Button, Card, Col, Image, Row, Space } from "antd";
import { AiOutlineClockCircle } from "react-icons/ai";
import { baseUrl, getProfile } from "../services/services.profile";
import { useState, useEffect } from "react";

function ArtpassID() {
  const [profile, setProfile] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    getProfile()
      .then((res) => {
        setProfile(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="flex gap-6 flex-col">
        <div className="flex flex-col sm:flex-row justify-center items-center gap-2 w-full">
          <div className="2xl:w-[6%] xl:w-[9%] w-full sm:w-[15%] flex justify-center items-center">
            <div className="w-[84px]  h-[84px]">
              <img
                className="w-full h-full"
                src={baseUrl + "/images/users/pdf-icon.png"}
                alt=""
              />
            </div>
          </div>
          <div className="bg-light flex flex-col md:flex-row items-start h-auto sm:h-20  md:items-center justify-center p-3 md:p-6 w-full xl:w-[91%] 2xl:w-[94%]  sm:w-[85%] gap-2">
            <p className="text-[15px] sm:whitespace-nowrap md:w-1/2">
              Customer Due Diligence (CDD) Report
            </p>
            <div className="flex justify-between items-center md:w-1/2 w-full md:p-3">
              <p className="text-[14px] flex items-center gap-[6px]">
                {/* <AiOutlineClockCircle /> */}
                {/* Expires: 18 Sep 2022 */}
              </p>
              <button
                className="bg-primary text-white px-3 py-[6px] h-9 text-[15px] rounded-lg"
                onClick={() => {
                  window
                    .open(baseUrl + "/profilereport/" + profile?._id, "_blank")
                    .focus();
                }}
              >
                View
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-center items-center gap-2 w-full">
          <div className="2xl:w-[6%] xl:w-[9%] w-full sm:w-[15%] flex justify-center items-center">
            <div className="w-[84px]  h-[84px]">
              <img
                className="w-full h-full"
                src={baseUrl + "/images/users/pdf-icon.png"}
                alt=""
              />
            </div>
          </div>
          <div className="bg-light flex flex-col md:flex-row items-start h-auto sm:h-20  md:items-center justify-center p-3 md:p-6 w-full xl:w-[91%] 2xl:w-[94%]  sm:w-[85%] gap-2">
            <p className="text-[15px] sm:whitespace-nowrap md:w-1/2">
              iPass ID
            </p>
            <div className="flex justify-between items-center md:w-1/2 w-full md:p-3">
              <p className="text-[14px] flex items-center gap-[6px]">
                {/* <AiOutlineClockCircle /> */}
                {/* Expires: 18 Sep 2022 */}
              </p>
              <button
                className="bg-primary text-white px-3 py-[6px] h-9 text-[15px] rounded-lg"
                onClick={() => {
                  window
                    .open(baseUrl + "/connectreport/" + profile?._id, "_blank")
                    .focus();
                }}
              >
                View
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArtpassID;
