import { Card, Col, Image, Row, Typography } from "antd";
import { getProfile } from "../services/services.profile";
import { useEffect, useState } from "react";
function ConnectionsCode() {
  const [profile, setProfile] = useState({});
  useEffect(() => {
    getProfile()
      .then((res) => {
        setProfile(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Row gutter={24} justify="stretch">
      <Col xs={24} sm={12}>
        <Card bordered={false} className="bg-light" style={{ height: "100%" }}>
          <Row justify="center" gutter={[16, 32]}>
            <Col>
              <Image src={profile?.connectRp?.qrCode} height={"250px"} />
            </Col>
            <Col>
              <Typography.Text>
                Share your iPass ID QR-Code so other iPass ID members can
                connect with you in the iPass ID app to share their CDD Report
                with you.
              </Typography.Text>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} sm={12}>
        <Card className="bg-light" bordered={false} style={{ height: "100%" }}>
          <Row justify="center" gutter={[16, 32]}>
            <Col>
              <div
                style={{
                  height: "250px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                #&nbsp;
                <Typography.Title level={2} copyable strong underline>
                  {profile.connectRp?.code}
                </Typography.Title>
              </div>
            </Col>
            <Col>
              <Typography.Text>
                Share your iPass ID code with other artpass ID members so they
                can send you connection requests. Created for people who like to
                work from the computer.
              </Typography.Text>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

export default ConnectionsCode;
